<script lang="ts" setup>
import { Button } from '@/components/ui/button/index'

defineProps<{
	product: App.Data.ProductData
}>()

const quantity = ref<number>(1)
const { addProductToCart, isCartLoading, isLoading } = useCart()

function resetQuantity() {
	quantity.value = 1
}
</script>

<template>
	<div class="flex flex-col rounded-lg border bg-white">
		<div class="relative h-32 rounded-t-lg bg-muted p-1">
			<img
				v-if="product.image"
				:src="product.image"
				:alt="product.title"
				class="size-full object-contain object-center mix-blend-multiply"
			/>

			<a
				v-if="product.url && !product.pos_only"
				:href="product.url"
				:title="product.title"
				target="_blank"
			>
				<i-gg-eye class="absolute right-2 top-2 size-4 text-primary hover:text-black" />
			</a>

			<template v-if="product.pos_only">
				<div class="absolute left-0 top-4 -ml-px rounded-r-lg bg-orange-500 px-2 py-1 text-sm font-semibold uppercase text-white">
					{{ $t('app.takeaway') }}
				</div>
			</template>
		</div>

		<div class="flex flex-1 flex-col gap-2 p-2">
			<h3 class="text-xs font-bold">
				{{ product.title }}
			</h3>

			<div class="mt-auto flex items-center justify-between gap-2">
				<p class="text-xs font-bold text-primary">
					{{ product.price?.display }}
				</p>

				<div class="flex items-center gap-1">
					<ui-number-field
						v-model="quantity"
						:default-value="quantity"
						@keyup.enter="isCartLoading ? null : addProductToCart(product, $event.target.value, function() {
							$event.target.value = 1
						})"
					>
						<ui-number-field-content>
							<ui-number-field-decrement />
							<ui-number-field-input />
							<ui-number-field-increment />
						</ui-number-field-content>
					</ui-number-field>

					<template v-if="product.type === 'simple' || product.type === 'external'">
						<ui-button :disabled="isCartLoading" class="max-w-[35px]" @click="addProductToCart(product, quantity, resetQuantity)">
							<i-gg-shopping-cart v-if="!isLoading" class="size-6 shrink-0" />
							<i-gg-spinner v-else class="size-6 shrink-0 animate-spin" />
						</ui-button>
					</template>
					<template v-else>
						<router-link
							:disabled="isCartLoading"
							:href="route('products.edit', { productId: product.id, quantity, product_name: product.title })"
							:as="Button"
							class="max-w-[35px]"
						>
							<i-gg-browse v-if="!isLoading" class="size-6 shrink-0" />
							<i-gg-spinner v-else class="size-6 animate-spin" />
						</router-link>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>
