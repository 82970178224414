<script setup lang="ts">
import printJS from 'print-js'

const $props = defineProps<{
	orderId: string
}>()

const { closeLocally } = useDialog()
const printLoading = ref<boolean>(true)

function printInvoice() {
	printJS({
		printable: route('api.invoice', { orderId: $props.orderId }),
		type: 'pdf',
	})
}

printJS({
	printable: route('api.invoice', { orderId: $props.orderId }),
	type: 'pdf',
	onLoadingEnd() {
		printLoading.value = false
	},
	onPrintDialogClose() {
		closeLocally()
	},
})
</script>

<template>
	<base-dialog :title="$t('app.order_completed', { order_id: orderId })">
		<ui-button class="gap-2" :disabled="printLoading" @click="printInvoice()">
			<i-gg-printer v-if="!printLoading" class="size-5" />
			<i-gg-spinner v-else class="size-5 animate-spin" />
			<span>{{ $t('app.print_invoice') }}</span>
		</ui-button>
	</base-dialog>
</template>
